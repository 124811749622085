// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f89984f6-09c7-4779-b770-247a2737771c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ZvKzLIlkK",
    "aws_user_pools_web_client_id": "66a1jfe46lrcc2ivmmh1r7biav",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://d4um47vc3nffnc4siuruvqnrfe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "flume-web-app-20191015134547-hostingbucket-uat",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2w6puozndx6y2.cloudfront.net",
    "aws_user_files_s3_bucket": "flume-web-app9527112a5fcb423ebdf18c86e35cf684-uat",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
